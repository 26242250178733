@import './_vars';

.design-unbound-main-elements.with-toc {
    .design-unbound-left-element {
        width: 20%;
        position: fixed;
        line-height: 1.5rem;

        .toc-section-link {
            font-weight: bold;
            display: block;

            &.highlighted {
                color: $design-unbound-red;
            }

            &.disabled {
                color: $design-unbound-grey;
                cursor: wait;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .design-unbound-right-element {
        width: 80%;
        margin-left: 20%;
        margin-top: 0;

        a {
            display: block;

            &:hover {
                text-decoration: none;
            }
        }
    }

    @media (max-width: $breakpoint-medium) {
        .design-unbound-left-element {
            width: 100%;
            background-color: white;
            margin-top: $main-content-padding * -1;
            padding-top: $main-content-padding;
        }

        .design-unbound-right-element {
            width: 100%;
            margin-left: 0;
        }
    }
}