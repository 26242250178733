@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Hair.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Hair.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-HairItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-HairItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Thin.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Thin.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-ThinItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-ThinItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Light.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-LightItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Regular.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Italic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Book.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Book.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-BookItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-BookItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Demi.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Demi.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-DemiItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-DemiItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Bold.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-BoldItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-ExtraBold.woff') format('woff'),
        url('../assets/mier-b-font/MierB-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-ExtraBoldItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Heavy.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Heavy.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-HeavyItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-HeavyItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-Black.woff') format('woff'),
        url('../assets/mier-b-font/MierB-Black.woff2') format('woff2');
    font-weight: 1000;
    font-style: normal;
}

@font-face {
    font-family: 'Mier B';
    src: url('../assets/mier-b-font/MierB-BlackItalic.woff') format('woff'),
        url('../assets/mier-b-font/MierB-BlackItalic.woff2') format('woff2');
    font-weight: 1000;
    font-style: italic;
}