@import './_font';
@import './nav';
@import './landing';
@import './chapter';
@import './chapters';
@import './bookCover';
@import './authors';
@import './comments';
@import './pageWithTOC';
@import './lookInside';

body {
    margin: 0;
    font-family: 'Mier B';
    font-weight: 500;
}

p {
    padding: 0;
    font-size: $font-size-p;
    line-height: $line-height-p;
    &:first-child { margin-top: 0; }
}

h1 {
    margin-top: 0;
    font-size: $font-size-h1;
}

h2 {
    font-size: $font-size-h2;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

#app-content {
    padding: $main-content-padding 12%;
}

.design-unbound-header-element {
    width: 75%;
    position: relative;
    max-width: 550px;
    font-weight: 700;
    margin-bottom: 1vh;
}

.design-unbound-main-elements {
    width: 100%;

    .design-unbound-left-element,
    .design-unbound-right-element {
        width: 100%;
        display: inline-block;
    }

    img {
        box-shadow: 1px 1px 8px rgba(0,0,0,.1);
        width: 100%;
    }

    .design-unbound-right-element {
        margin-top: $vertical-spacer;
    }
}

.link-with-icon {
    margin-bottom: $vertical-spacer / 2;
    display: block;

    svg {
        margin-bottom: -7px;
        margin-right: 6px;
    }
}

.purchase-button {
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 100px;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    text-decoration: none;
    padding: 18px;
    cursor: pointer;
}

#app-footer {
    text-align: center;
    border-top: 1px solid $design-unbound-footer-color;
    margin-top: $vertical-spacer * 2;
    padding-top: $vertical-spacer;

    p {
        font-size: .75rem;
        color: $design-unbound-grey;
        margin: 0;
        line-height: 1.25rem;
    }
}

@media (max-width: $breakpoint-small) {
    h1 { font-size: $font-size-h1-small; }
    p { line-height: $line-height-p-small; }
}

@media (max-width: $breakpoint-medium) {
    h2 { font-size: $font-size-h2-small; }
    .design-unbound-header-element { width: 100%; }
}

@media (min-width: $breakpoint-large) {
    .design-unbound-main-elements {
        display: flex;
        justify-content: space-between;
        
        .design-unbound-left-element {
            width: 60%;
        }
    
        .design-unbound-right-element {
            width: 40%;
            padding-left: $horizontal-spacer;
            margin-top: 0;
        }
    }
}