@import './_vars';

#authors-container {
    h2:first-of-type {
        margin-top: 0;
    }

    .author-jsb {
        margin-top: $vertical-spacer * 2;
    }

    .author-separator {
        margin: 4vh auto;
        width: 80%;
        color: $design-unbound-grey;
        opacity: 0.25;
    }

    @media (min-width: $breakpoint-large) {    
        .design-unbound-left-element {
            width: 35%;
        }
    
        .design-unbound-right-element {
            width: 65%;
        }
    }
}