@import './_vars';

#chapters-container {
    .chapter-covers {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chapter-cover-container {
        width: 32%;
        display: block;
        margin-bottom: 2vh;

        img { width: 100%; }
    }

    @media (min-width: $breakpoint-large) {
        .chapter-cover-container { width: 23%; }
    }
    
    @media (max-width: $breakpoint-medium) {
        .chapter-cover-container { width: 48%; }
    }

    @media (max-width: $breakpoint-small) {
        .chapter-cover-container { width: 100%; }
    }
}