@import './_vars';

$space-between-comments: 4vh;

#comments-container {

    .design-unbound-comment {
        width: 75%;
        clear: both;

        &.odd {
            margin-left: 25%;
            text-align: right;

            .design-unbound-comment-author {
                float: right;
            }
        }

        p:last-of-type {
            margin-bottom: 1vh;
        }

        &.has-img {
            margin: 0 auto;
            margin-bottom: $space-between-comments;
        }

        .design-unbound-comment-author {
            line-height: $line-height-p-small;
            font-size: 0.75rem;
            width: 70%;
            display: inline-block;
            margin-bottom: $space-between-comments;

            .author {
                font-weight: bold;
            }

            .desc {
                font-style: italic;
            }
        }
    }

    @media (max-width: $breakpoint-large) {
        .design-unbound-right-element .design-unbound-comment {
            width: 80%;
            margin-left: 5%;

            &.odd {
                margin-left: 15%;
                text-align: right;
            }

            &.has-img {
                margin: 0 auto;
                margin-bottom: $space-between-comments;
            }
        }
    }

    @media (max-width: $breakpoint-medium) {
        .design-unbound-left-element {
            height: 64px;
        }

        .design-unbound-right-element {
            margin-top: 64px;

            .design-unbound-comment {
                width: 100%;
                margin-left: 0;

                .design-unbound-comment-author {
                    width: 90%;
                }

                &.odd {
                    margin-left: 0;
                }
            }
        }
    }
}