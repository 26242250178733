// breakpoints
$breakpoint-small: 600px;
$breakpoint-medium: 960px;
$breakpoint-large: 1280px;
$breakpoint-extra-large: 1920px;

// colors
$design-unbound-red: #FF0000;
$design-unbound-maroon: #902520;
$design-unbound-grey: #6e6e70;
$design-unbound-magenta: #d9338b;
$design-unbound-green: #4b7356;
$design-unbound-blue: #284289;
$design-unbound-footer-color: rgba(0,0,0,0.3);

// font-sizes & line-heights
$font-size-h1: 2rem;
$font-size-h1-small: 1.5rem;

$font-size-h2: 1.5rem;
$font-size-h2-small: 1.25rem;

$font-size-p: 1rem;
$line-height-p: 1.5rem;
$line-height-p-small: 1.25rem;

// random variables
$nav-control-top-padding: 12vh; 
$nav-control-height: 8vh;
$nav-control-max-height: 80px;
$vertical-spacer: 2vh;
$vertical-spacer-static: 36px;
$horizontal-spacer: 2vw;
$border-radius: 2px;
$main-content-padding: 6vh;