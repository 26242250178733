@import '_vars';

#look-inside-container {
    .look-inside-img {
        width: 58%;
        margin-left: 21%;
    }

    @media (max-width: $breakpoint-medium) {
        .design-unbound-left-element {
            height: 105px;

            // cover box shadow for images
            margin-left: -10px;
            padding-left: 10px;
        }

        .design-unbound-right-element {
            margin-top: 105px;
        }

        .look-inside-img {
            margin-left: 0;
            width: 100%;
        }
    }
}