@import './_vars';

/**
 * Animation definitions & variables
 */
 @keyframes moveNavRight {
    0% { left: 0; }
    100% { left: 100vw; }
}

@keyframes moveNavLeft {
    0% { left: 100vw; }
    100% { left: 0; }
}

@keyframes moveMenuRight {
    0% { left: -100vw; }
    100% { left: 0; }
}

@keyframes moveMenuLeft {
    0% { left: 0; }
    100% { left: -100vw; }
}

$nav-animation-duration: 500ms;
$nav-animation-duration-slow: 750ms;
$nav-animation-timing-function: ease-in-out;

/**
 * Styles
 */

nav {
    position: fixed;
    z-index: 1;
    font-size: 18px;
}

#nav-control {
    cursor: pointer;
    background-color: $design-unbound-red;
    position: fixed;
    border-radius: 100%;
    width: $nav-control-height;
    height: $nav-control-height;
    max-height: $nav-control-max-height;
    max-width: $nav-control-max-height;
    top: $nav-control-top-padding;
    left: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 2;

    &.animate-right {
        animation: moveNavRight;
        animation-duration: $nav-animation-duration-slow;
        animation-timing-function: $nav-animation-timing-function;
        left: 100vw;
    }

    &.animate-left {
        animation: moveNavLeft;
        animation-duration: $nav-animation-duration-slow;
        animation-timing-function: $nav-animation-timing-function;
        left: 0;
    }

    svg {
        fill: white;
        width: $nav-control-height / 2;
        height: $nav-control-height / 2;
        max-height: $nav-control-max-height / 2;
        max-width: $nav-control-max-height / 2;
        position: absolute;
        opacity: 1;

        &.hide { opacity: 0; }
        &.menu-icon { right: 0.5vh; }
        &.close-icon { margin-left: -1vw; }
    }
}

#nav-content {
    background-color: $design-unbound-red;
    height: 110vh;
    margin-top: -10vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 0;
    left: -101vw;

    .nav-link {
        padding: 12px 0;
        width: 100%;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
    
        &.active {
            background-color: white;
            color: black;
            font-weight: 500;
        }
    }

    &.animate-right {
        animation: moveMenuRight;
        animation-duration: $nav-animation-duration-slow;
        animation-timing-function: $nav-animation-timing-function;
        left: 0;
    }

    &.animate-left {
        animation: moveMenuLeft;
        animation-duration: $nav-animation-duration-slow;
        animation-timing-function: $nav-animation-timing-function;
        left: -101vw;
    }
}

@media (max-width: $breakpoint-medium) {
    nav { font-size: 16px; }

    #nav-control {
        &.animate-left,
        &.animate-right {
            animation-duration: $nav-animation-duration;
        }
    }

    #nav-content {
        &.animate-left,
        &.animate-right {
            animation-duration: $nav-animation-duration;
        }
    }
}


