@import './_vars';

#design-unbound-volumes-container {
    .main-title {
        margin-bottom: 6px;
    }

    .mit-press-tag {
        margin: 0;
    }

    .author-tag,
    .volume-tag,
    .mit-press-tag {
        font-weight: 600;
    }

    .design-unbound-volume-cover {
        width: 50%;
    }

    .link-set {
        margin-top: $vertical-spacer-static;
    }

    .design-unbound-chapter-container {
        width: 100%;
        margin-top: $vertical-spacer-static;

        .design-unbound-chapters {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: $vertical-spacer;

            .chapter {
                width: 32%;
                img { width: 100% }
            }
        }

        h2 {
            margin-bottom: 0;
        }

        .chapters-link {
            display: block;
            width: 100%;
            margin-bottom: 0;
            text-decoration: underline;

            svg { margin-bottom: -4px; }
        }
    }

    @media (max-width: $breakpoint-small) {
        .design-unbound-chapter-container .design-unbound-chapters {
            margin-top: $vertical-spacer * 2;
        }
    }
}