@import './_vars';

.book-cover-container {
    position: relative;
    
    img {
        border-radius: $border-radius;
    }

    .overlay, .overlay-content {
        height: 100%;
        position: absolute;
        top: 0;
    }

    .overlay {
        background-color:rgba(0,0,0, 0.8);
        display: none;
        opacity: 0.95;
        border-radius: $border-radius;
        width: 100%;
    }

    .overlay-content {
        font-size: .8rem;
        font-weight: 600;
        width: 90%;
        display: none;
        color: white;
        margin-left: 5%;

        .read-more {
            margin-top: 1vh;
            text-decoration: underline;

            svg {
                fill: white;
                height: 18px;
                width: 18px; 
                margin-bottom: -5px;
            }
        }
    }

    .banner-container {
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: absolute;
        top: -5px;
        right: -5px;

        &:after {
            content: "";
            display: block;
            width: 10px;
            height: 5px;
            background-color: darken(black, 5%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        &:before {
            content: "";
            display: block;
            height: 10px;
            width: 5px;
            background-color: darken(black, 5%);
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
        }

        .banner {
            width: 200px;
            padding: 15px;
            background-color: black;
            color: #fff;
            text-align: center;
            font-size: 12px;
            transform: rotate(45deg) translate(4%, 93%);
            box-shadow: 5px 2px 5px rgba(0,0,0,0.4);
            text-transform: uppercase;
            text-shadow: 0 2px 2px rgba(0,0,0,0.4);
        }
    }

    &.showOverlay,
    &:hover {
        .overlay-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .overlay {
            display: block;
        }
        
        .banner-container {
            display: none;
        }
    }
}

@media (min-width: $breakpoint-extra-large) {
    .book-cover-container .overlay-content {
        font-size: 1rem;
    }
}