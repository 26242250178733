@import './_vars';

.chapters-link {
    text-decoration: none;
    line-height: $line-height-p;
    display: flex;
    align-items: center;
    color: black;

    svg {
        width: 18px; 
        height: 18px; 
    }
}

.download-button {
    width: 50%;
    display: inline-block;
    float: left;
}

.button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: $vertical-spacer-static;

    &:has(.download-button) {
        justify-content: space-between;
    }

    div { display: inline-block; }

    button {
        cursor: pointer;
    }

    .download-button-container {
        width: 50%;
    }

    .purchase-button-container {
        display: flex;
        justify-content: flex-end;
        width: 50%;
    }

    .black-and-white {
        background-color: transparent;
        color: black;
        margin-right: 18px;
    }
}

@media (max-width: $breakpoint-small) {
    .button-container {
        display: block;

        .download-button-container, 
        .purchase-button-container {
            width: 100%;
        }

        .purchase-button-container {
            margin-top: $vertical-spacer-static;
            justify-content: center;
        }
    }
}